/* eslint-disable react/display-name */
import * as React from 'react';

import { cn } from '@shadcn/lib/utils';
import { useFormField } from './form';
import { Button, ButtonProps } from '../../../_components/Button';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	comment?: string | React.ReactElement;
}

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, comment, ...props }, ref) => {
		const { error } = useFormField();
		const classNames = [className, error ? 'border-error focus:border-error' : ''].join(' ');

		return (
			<>
				<input
					type={type}
					className={cn(
						'focus:border-gold-light flex h-10 w-full max-w-96 rounded-lg border border-neutral-200 bg-white px-3 py-2 text-base ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-400 focus:outline-hidden disabled:cursor-not-allowed disabled:opacity-50 lg:text-sm',
						classNames,
					)}
					ref={ref}
					{...props}
				/>
				{comment && (
					<p className="pl-2 text-sm font-medium text-black/[0.64] italic">{comment}</p>
				)}
			</>
		);
	},
);

FormInput.displayName = 'Input';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		const classNames = [className].join(' ');

		if (type === 'number') {
			return (
				<span className="flex h-12 items-center rounded-lg border border-neutral-200 bg-white py-2 text-sm ring-offset-white">
					<input
						type="number"
						className={cn(
							'max-w-10 flex-1 justify-center text-center leading-8 [-moz-appearance:_textfield] file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-400 focus:outline-hidden disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none',
							classNames,
						)}
						ref={ref}
						{...props}
					/>
				</span>
			);
		}

		return (
			<>
				<input
					type={type}
					className={cn(
						'focus:border-gold-light flex h-10 w-full rounded-lg border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-400 focus:outline-hidden disabled:cursor-not-allowed disabled:opacity-50',
						classNames,
					)}
					ref={ref}
					{...props}
				/>
			</>
		);
	},
);

type ReactInputProps = React.InputHTMLAttributes<HTMLInputElement>;
export type CounterInputProps = {
	value: number;
	setValue: (arg0: number) => void;
	maxCount: number;
	minimumValue?: number;
	size?: ButtonProps['size'];
	isLoading?: boolean;
} & Omit<ReactInputProps, 'size'>;

const CounterInput = React.forwardRef<HTMLInputElement, CounterInputProps>(
	(
		{
			className,
			value,
			setValue,
			maxCount,
			minimumValue = 1,
			size = 'small',
			isLoading,
			...props
		},
		ref,
	) => {
		const isLoadingClassnames = 'animate-pulse rounded-md bg-neutral-100';
		const classNames = [className].join(' ');

		return (
			<span
				className={`focus-within:border-gold-light flex h-12 w-[120px] items-center rounded-lg border border-neutral-200 bg-white py-2 text-sm ring-offset-white ${size === 'large' ? 'h-16' : ''} ${isLoading ? isLoadingClassnames : ''}`}
			>
				<Button
					className="hover:text-gold flex-none p-4 text-xl font-light hover:no-underline"
					size={size}
					variant="ghost"
					onClick={() => {
						setValue(value - 1);
					}}
					disabled={value <= minimumValue || isLoading}
				>
					-
				</Button>

				<input
					type="number"
					readOnly
					className={cn(
						'w-2 flex-1 justify-center text-center leading-8 [-moz-appearance:_textfield] file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-400 focus:outline-hidden disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none',
						classNames,
					)}
					ref={ref}
					value={value}
					{...props}
				/>

				<Button
					className="hover:text-gold flex-none p-4 text-xl font-light hover:no-underline"
					size={size}
					variant="ghost"
					onClick={() => {
						setValue(value + 1);
					}}
					disabled={value >= maxCount || isLoading}
				>
					+
				</Button>
			</span>
		);
	},
);
Input.displayName = 'Input';

export { Input, FormInput, CounterInput };
